<template>
  <el-container style="height: 100vh; display: flex; justify-content: center; align-items: center;">
    <el-card class="box-card custom-card" shadow="hover" v-loading="loading">
      <!-- Header section with title and subtitle -->
      <div class="card-header">
        <el-image
          :src="vipIcon"
          class="vip-icon"
        ></el-image>
        <div class="card-header-text">
          <h2 class="card-title">AF VIP Purchase</h2>
          <p class="card-subtitle">Subscribe to VIP plan</p>
        </div>
      </div>
      <!-- Form section -->
      <el-form :model="form" :rules="rules" ref="formRef" label-position="top">
        <!-- Email fields with error messages -->
        <el-form-item label="Email" prop="email">
          <el-input v-model="form.email" placeholder="Email of Android Faker"></el-input>
        </el-form-item>
        <el-form-item label="Confirm Email" prop="confirmEmail">
          <el-input v-model="form.confirmEmail" placeholder="Enter your email again"></el-input>
        </el-form-item>

        <!-- Subscription plan selection -->
        <el-form-item label="Subscription Plan">
          <el-select v-model="form.selectedItem" placeholder="Select plan">
            <el-option
              v-for="item in selectItems"
              :key="item.value"
              :label="item.text"
              :value="item.price"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- PayPal button container -->
        <div id="paypal-button-container" class="mt-3"></div>
      </el-form>
    </el-card>



    <!-- Payment Successful Dialog -->
    <el-dialog
      title="Payment Successful"
      v-model="paymentSuccessDialogVisible"
      width="500px">
      <span>Your payment was successful! Thank you for your purchase.</span>
      <template #footer>
       <el-button type="primary" @click="paymentSuccessDialogVisible = false">OK</el-button>
     </template>
    </el-dialog>
  </el-container>
</template>

<script setup>
import { ref, onMounted, computed, reactive, watch } from 'vue';
import 'element-plus/theme-chalk/el-loading.css';

const actionStatus = ref(null); 
const loading = ref(true);
const vipIcon = require('@/assets/icons/vip-icon.png');

const dialogVisible = ref(false); // Define dialogVisible for the test dialog
const paymentSuccessDialogVisible = ref(false); // Existing dialog ref

const selectItems = [
  { text: 'VIP three months - 4 USD', value: 'VIP three months', price: '4' },
  { text: 'VIP six months - 9 USD', value: 'VIP six months', price: '9' },
  { text: 'VIP one year - 14 USD', value: 'VIP one year', price: '14' },
];

const itemDescription = computed(() => {
  const selectedPlan = selectItems.find(item => item.price === form.selectedItem);
  return selectedPlan ? selectedPlan.value : '';
});

const form = reactive({
  email: '',
  confirmEmail: '',
  selectedItem: selectItems[0].price, // Set the default item
});

const formRef = ref(null);

const rules = reactive({
  email: [
    { required: true, message: 'Email is required', trigger: 'blur' },
    { type: 'email', message: 'Email not valid', trigger: ['blur', 'change'] }
  ],
  confirmEmail: [
    { required: true, message: 'Confirm Email is required', trigger: 'blur' },
    { validator: (rule, value, callback) => {
        if (value !== form.email) {
          callback(new Error('Emails must match'));
        } else {
          callback();
        }
      }, trigger: ['blur', 'change']
    }
  ]
});

const isEmailValid = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const updateActionStatus = () => {
  if (isEmailValid(form.email) && form.email === form.confirmEmail) {
    actionStatus.value.enable();
    return true;
  }
  actionStatus.value.disable();
  return false;
};

const checkForm = () => {
  const sEmail = form.email;
  const sConfirm = form.confirmEmail;

  if (sEmail.length == 0) {
    formRef.value.validateField('email', (error) => {
      if (error) console.error(error.message);
    });
    return false;
  } else if (!isEmailValid(sEmail)) {
    formRef.value.validateField('email', (error) => {
      if (error) console.error(error.message);
    });
    return false;
  } else if (sEmail !== sConfirm) {
    formRef.value.validateField('confirmEmail', (error) => {
      if (error) console.error(error.message);
    });
    return false;
  } else {
    return true;
  }
};

watch(() => form.email, () => { updateActionStatus(); });
watch(() => form.confirmEmail, () => { updateActionStatus(); });

onMounted(() => {
  const script = document.createElement('script');
  script.src = 'https://www.paypal.com/sdk/js?client-id=AbPXAn9BGj0uS1fv3L3pSG3MAoUDeEpZDa5m9gn6RMGdEf-_WaKN3afVVziVgubXnTV1wR4oCnAn_ZEN&currency=USD'; // Replace with your client ID
  script.addEventListener('load', () => {
    window.paypal.Buttons({
      style: {
        color: 'blue'
      },
      onInit: function (data, actions) {
        actionStatus.value = actions;
        updateActionStatus();
        loading.value = false;
      },
      onClick: function () {
        checkForm();
      },
      createOrder: function (data, actions) {
        if (!updateActionStatus()) {
          return null;
        }
        console.log(form.selectedItem);
        return actions.order.create({
          purchase_units: [{
            description: form.email + ' - AF ' + itemDescription.value,
            amount: {
              currency_code: 'USD',
              value: form.selectedItem
            },
            custom_id: form.email
          }]
        });
      },
      onApprove: function (data, actions) {
        return actions.order.capture().then(function (details) {
          paymentSuccessDialogVisible.value = true;
        });
      },
      onError: function (err) {
        console.log(err)
      },
    }).render('#paypal-button-container');
  });
  document.body.appendChild(script);
});
</script>

<style scoped>
.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1rem;
}

.vip-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.card-header-text {
  flex-grow: 1;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #409eff;
  margin: 0;
}

.card-subtitle {
  font-size: 1rem;
  color: #909399;
  margin: 0;
}

.el-card {
  border-radius: 10px;
  overflow: hidden;
  width: 350px;
}

.el-form-item {
  margin-bottom: 1.5rem;
}

.el-input, .el-select {
  width: 100%;
}

.mt-3 {
  margin-top: 1.5rem;
}
</style>
